<template>
  <div class="Client font">
    <!-- BEGIN: Content-->
    <div class="app-content content">
      <div class="content-overlay"></div>
      <div class="header-navbar-shadow"></div>
      <div class="content-wrapper container-xxl p-0 font">
        <banner title="CREATE ADMIN"> </banner>
        <div v-if="loading == true">
          <Spinner />
        </div>
        <div class="row font" style="margin-top: -45px">
          <div class="col-xl-12 col-md-12 col-sm-12">
            <div
              class="card custom-card"
              style="height: 100%"
            >
              <div class="card-header">
                <h4>
                  <b style="color: #00364f; font-weight: bolder">Admin Information</b>
                </h4>
              </div>
              <div class="card-body" style="margin-top: -15px">
                <form
                  method="POST"
                  @submit.prevent="
                    routerIdRecived == 'false' ? createAdmin() : updateAdmin()
                  "
                >
                  <h4 style="color: #f21300"><b>Business Details</b></h4>
                  <!-- row 1 -->
                  <div class="row">
                    <div class="col-12" v-if="msgPanError">
                      <div class="alert alert-danger p-1" role="alert">
                        <small>{{ msgPanError }}</small>
                      </div>
                    </div>
                    <div
                      class="col-md-3 col-xl-3 col-lg-3 col-sm-6 col-12"
                      style="padding: 5px 5px 5px 5px"
                    >
                      <div class="form-group">
                        <label>Business Pan <span style="color: #f21000">*</span></label>
                        <div v-if="panVarifyingStatus == 'true'">
                          <input
                            required
                            disabled
                            type="tel"
                            maxlength="10"
                            v-model="form.businessPan"
                            class="form-control"
                            placeholder="Business Pan Number"
                            style=" height: 30px"
                            @change="businessPanVeryfied()"
                            @keydown="nameKeydown($event)"
                          />
                        </div>
                        <div v-if="panVarifyingStatus == 'false'">
                          <input
                            required
                            type="tel"
                            maxlength="10"
                            v-model="form.businessPan"
                            class="form-control"
                            placeholder="Business Pan Number"
                            style=" height: 30px"
                            @change="businessPanVeryfied()"
                            @keydown="nameKeydown($event)"
                          />
                        </div>

                        <span style="color: #f21000" id="businessPanVeryfiedAdmin"></span>
                        <small style="color: red" v-if="form.errors"
                          ><span v-if="form.errors.error"
                            ><span v-if="form.errors.error.businessPan">{{
                              form.errors.error.businessPan[0]
                            }}</span></span
                          ></small
                        >
                      </div>
                    </div>

                    <div
                      class="col-md-3 col-xl-3 col-lg-3 col-sm-6 col-12"
                      style="padding: 5px 5px 5px 5px"
                    >
                      <div class="form-group">
                        <label>Business Name <span style="color: #f21000">*</span></label>
                        <input
                          disabled
                          required
                          type="text"
                          v-model="form.orgnization"
                          class="form-control"
                          placeholder=" Business Name"
                          style=" height: 30px"
                        />
                        <small style="color: red" v-if="form.errors"
                          ><span v-if="form.errors.error"
                            ><span v-if="form.errors.error.orgnization">{{
                              form.errors.error.orgnization[0]
                            }}</span></span
                          ></small
                        >
                      </div>
                    </div>
                    <div
                      class="col-md-3 col-xl-3 col-lg-3 col-sm-6 col-12"
                      style="padding: 5px 5px 5px 5px"
                    >
                      <div class="form-group">
                        <label>Phone <span style="color: #f21000">*</span></label>
                        <input
                          type="number"
                          maxlength="10"
                          required
                          v-model="form.phone"
                          class="form-control"
                          placeholder="Mobile Number"
                          style=" height: 30px"
                          @change="lenthheker($event, 'phone')"
                        />
                        <span style="color: #f21000" id="demophoneval"></span>
                        <small style="color: red" v-if="form.errors"
                          ><span v-if="form.errors.error"
                            ><span v-if="form.errors.error.phone">{{
                              form.errors.error.phone[0]
                            }}</span></span
                          ></small
                        >
                      </div>
                    </div>
                    <div
                      class="col-md-3 col-xl-3 col-lg-3 col-sm-6 col-12"
                      style="padding: 5px 5px 5px 5px"
                    >
                      <div class="form-group">
                        <label>Alt Number</label>
                        <input
                          type="number"
                          maxlength="10"
                          v-model="form.alternativePhone"
                          class="form-control"
                          placeholder=" Alternate Mobile Number"
                          style=" height: 30px"
                        />
                        <span style="color: #f21000" id="demoAltphoneval"></span>
                      </div>
                    </div>

                    <div
                      class="col-md-3 col-xl-3 col-lg-3 col-sm-6 col-12"
                      style="padding: 5px 5px 5px 5px"
                    >
                      <div class="form-group">
                        <label>Email ID <span style="color: #f21000">*</span></label>
                        <input
                          required
                          v-model="form.email"
                          type="email"
                          class="form-control"
                          placeholder="Official Email Id"
                          style="height: 30px"
                        />
                        <small style="color: red" v-if="form.errors"
                          ><span v-if="form.errors.error"
                            ><span v-if="form.errors.error.email">{{
                              form.errors.error.email[0]
                            }}</span></span
                          ></small
                        >
                      </div>
                    </div>

                    <div
                      class="col-md-3 col-xl-3 col-lg-3 col-sm-6 col-12"
                      style="padding: 5px 5px 5px 5px"
                    >
                      <div class="form-group">
                        <label>DOI <span style="color: #f21000">*</span></label>
                        <input
                          required
                          style=" height: 30px"
                          v-model="form.doi"
                          type="text"
                          class="form-control"
                          onfocus="(this.type = 'date')"
                          placeholder="Date Of Incorporation"
                          @change="dateCheker($event, 'doiDate')"
                        />
                        <span style="color: #f21000" id="doiDate"></span>
                        <small style="color: red" v-if="form.errors"
                          ><span v-if="form.errors.error"
                            ><span v-if="form.errors.error.doi">{{
                              form.errors.error.doi[0]
                            }}</span></span
                          ></small
                        >
                      </div>
                    </div>

                    <div
                      class="col-md-3 col-xl-3 col-lg-3 col-sm-6 col-12"
                      style="padding: 5px 5px 5px 5px"
                    >
                      <div class="form-group">
                        <label>GstIn <span style="color: #f21000">*</span></label>
                        <input
                        maxlength="15"
                          required
                          type="tel"
                          v-model="form.gstin"
                          class="form-control"
                          placeholder="GSTIN"
                          style=" height: 30px"
                          @change="lenthheker($event, 'gstinVal')"
                          @keydown="nameKeydownGst($event)"
                        />
                        <span style="color: #f21000" id="gstinVal"></span>
                        <small style="color: red" v-if="form.errors"
                          ><span v-if="form.errors.error"
                            ><span v-if="form.errors.error.gstin">{{
                              form.errors.error.gstin[0]
                            }}</span></span
                          ></small
                        >
                      </div>
                    </div>

                    <div
                      class="col-md-3 col-xl-3 col-lg-3 col-sm-6 col-12"
                      style="padding: 5px 5px 5px 5px"
                    >
                      <div class="form-group">
                        <label>CIN <span style="color: #f21000">*</span></label>
                        <input
                          required
                          type="text"
                          v-model="form.cin"
                          class="form-control"
                          placeholder="CIN"
                          style=" height: 30px"
                        />
                      </div>
                      <small style="color: red" v-if="form.errors"
                        ><span v-if="form.errors.error"
                          ><span v-if="form.errors.error.cin">{{
                            form.errors.error.cin[0]
                          }}</span></span
                        ></small
                      >
                    </div>
                    <div
                      class="col-md-3 col-xl-3 col-lg-3 col-sm-6 col-12"
                      style="padding: 5px 5px 5px 5px"
                    >
                      <div class="form-group">
                        <label>TAN</label>
                        <input
                          type="text"
                          v-model="form.tds"
                          class="form-control"
                          placeholder="TAN"
                          style=" height: 30px"
                        />
                        <small style="color: red" v-if="form.errors"
                          ><span v-if="form.errors.error"
                            ><span v-if="form.errors.error.tds">{{
                              form.errors.error.tds[0]
                            }}</span></span
                          ></small
                        >
                      </div>
                    </div>

                    <div
                      class="col-md-3 col-xl-3 col-lg-3 col-sm-6 col-12"
                      style="padding: 5px 5px 5px 5px"
                    >
                      <div class="form-group">
                        <label
                          >Business Address <span style="color: #f21000">*</span></label
                        >
                        <input
                          required
                          type="text"
                          v-model="form.businessAddress"
                          class="form-control"
                          placeholder="Registered Business Address"
                          style=" height: 30px"
                          @change="lenthheker($event, 'businessAddress')"
                        />
                        <span style="color: #f21000" id="businessAddress"></span>
                      </div>
                    </div>
                    <div
                      class="col-md-3 col-xl-3 col-lg-3 col-sm-6 col-12"
                      style="padding: 5px 5px 5px 5px"
                    >
                      <div class="form-group">
                        <label>City <span style="color: #f21000">*</span></label>
                        <input
                          required
                          type="text"
                          v-model="form.businessCity"
                          class="form-control"
                          placeholder="Business City"
                          style=" height: 30px"
                          @change="lenthheker($event, 'bussinessCity')"
                        />
                        <span style="color: #f21000" id="bussinessCity"></span>
                      </div>
                    </div>

                    <div
                      class="col-md-3 col-xl-3 col-lg-3 col-sm-6 col-12"
                      style="padding: 5px 5px 5px 5px"
                    >
                      <div class="form-group">
                        <label>State <span style="color: #f21000">*</span></label>
                        <select
                          required
                          style="
                            
                            height: 30px;
                            padding: 5px 5px 5px 5px;
                          "
                          class="form-select"
                          v-model="form.business_state_id"
                          aria-label="Default select example"
                        >
                          <!-- <option selected>States</option> -->
                          <option
                            v-for="(state, index) in states"
                            :key="index"
                            :value="state.id"
                          >
                            {{ state.name }}
                          </option>
                        </select>
                      </div>
                    </div>
                    
                    <div
                      class="col-md-3 col-xl-3 col-lg-3 col-sm-6 col-12"
                      style="padding: 5px 5px 5px 5px"
                    >
                      <div class="form-group">
                        <label>Pin Code <span style="color: #f21000">*</span></label>
                        <input
                          required
                          maxlength="6"
                          type="number"
                          style=" height: 30px"
                          v-model="form.businessPin"
                          class="form-control"
                          placeholder="Business Pin"
                          @change="lenthheker($event, 'businessPinCode')"
                        />
                        <span style="color: #f21000" id="businessPinCode"></span>
                        <small style="color: red" v-if="form.errors"
                          ><span v-if="form.errors.error"
                            ><span v-if="form.errors.error.businessPin">{{
                              form.errors.error.businessPin[0]
                            }}</span></span
                          ></small
                        >
                      </div>
                    </div>
                  </div>

                  <!-- row 3 -->

                  <div class="row mt-1">
                    <div class="col-12" v-if="msgImgError">
                      <div class="alert alert-danger p-1" role="alert">
                        <small>{{ msgImgError }}</small>
                      </div>
                    </div>

                    <div class="col-2">
                      <div>
                        <label for="file-input2">  <img style="width: 50%" v-if="logoPreImg" :src="logoPreImg" /></label>
                      
                      </div>
                      <div class="d-flex">
                        <!-- <label for="file-input2"> -->
                          <strong>Logo</strong>&nbsp;&nbsp;
                          <!-- <img
                            style="width: 25px; height: 25px"
                            src="/assets/image/upload.png"
                          /> -->
                        <!-- </label> -->
                        <input
                          @change="logoUpload"
                          style="position: absolute; top: -150000px"
                          id="file-input2"
                          type="file"
                        />
                        <small style="color: red" v-if="form.errors"
                          ><span v-if="form.errors.error"
                            ><span v-if="form.errors.error.logo">{{
                              form.errors.error.logo[0]
                            }}</span></span
                          ></small
                        >
                      </div>
                    </div>
                  </div>
                  <h4 class="mt-2" style="color: #f21300">
                    <b>Authorised Person Details</b>
                  </h4>
                  <div class="row mt-1" style="margin-left: 0px; margin-right: -5px">
                    <div
                      class="col-md-3 col-xl-3 col-lg-3 col-sm-6 col-12"
                      style="padding: 5px 5px 5px 5px"
                    >
                      <div class="form-group">
                        <label>Admin Name <span style="color: #f21000">*</span></label>
                        <input
                          required
                          type="text"
                          class="form-control"
                          v-model="form.name"
                          placeholder="Admin Name"
                          style=" height: 30px"
                          @change="lenthheker($event, 'adminName')"
                        />
                        <span style="color: #f21000" id="adminName"></span>
                        <small style="color: red" v-if="form.errors"
                          ><span v-if="form.errors.error"
                            ><span v-if="form.errors.error.name">{{
                              form.errors.error.name[0]
                            }}</span></span
                          ></small
                        >
                      </div>
                    </div>
                    <div
                      class="col-md-3 col-xl-3 col-lg-3 col-sm-6 col-12"
                      style="padding: 5px 5px 5px 5px"
                    >
                      <div class="form-group">
                        <label>DOB <span style="color: #f21000">*</span></label>
                        <input
                          required
                          type="text"
                          v-model="form.dob"
                          class="form-control"
                          onfocus="(this.type = 'date')"
                          placeholder="Date Of Birth"
                          style=" height: 30px"
                          @change="dateCheker($event, 'doBDate')"
                        />
                        <span style="color: #f21000" id="doBDate"></span>
                        <small style="color: red" v-if="form.errors"
                          ><span v-if="form.errors.error"
                            ><span v-if="form.errors.error.dob">{{
                              form.errors.error.dob[0]
                            }}</span></span
                          ></small
                        >
                      </div>
                    </div>
                    <div
                      class="col-md-3 col-xl-3 col-lg-3 col-sm-6 col-12"
                      style="padding: 5px 5px 5px 5px"
                    >
                      <label>Gender <span style="color: #f21000">*</span></label>
                      <select
                        style="
                          
                          height: 30px;
                          padding: 5px 5px 5px 5px;
                        "
                        required
                        class="form-select"
                        v-model="form.gender"
                        aria-label="Default select example"
                      >
                        <option selected value="male">Male</option>
                        <option value="female">FeMale</option>
                      </select>
                    </div>
                  </div>
                  <div class="row mt-1" style="margin-left: 0px; margin-right: -5px">
                    <div
                      class="col-md-3 col-xl-3 col-lg-3 col-sm-6 col-12"
                      style="padding: 5px 5px 5px 5px"
                    >
                      <div class="form-group">
                        <label>Address <span style="color: #f21000">*</span></label>
                        <input
                          required
                          style=" height: 30px"
                          type="text"
                          class="form-control"
                          v-model="form.address"
                          placeholder="Address"
                          @change="lenthheker($event, 'adminAddress')"
                        />
                        <span style="color: #f21000" id="adminAddress"></span>
                      </div>
                    </div>
                    <div
                      class="col-md-3 col-xl-3 col-lg-3 col-sm-6 col-12"
                      style="padding: 5px 5px 5px 5px"
                    >
                      <div class="form-group">
                        <label>Ctiy <span style="color: #f21000">*</span></label>
                        <input
                          required
                          style=" height: 30px"
                          type="text"
                          v-model="form.city"
                          class="form-control"
                          placeholder="City"
                          @change="lenthheker($event, 'adminCity')"
                        />
                        <span style="color: #f21000" id="adminCity"></span>
                      </div>
                    </div>
                    <div
                      class="col-md-3 col-xl-3 col-lg-3 col-sm-6 col-12"
                      style="padding: 5px 5px 5px 5px"
                    >
                      <div class="form-group">
                        <label>State <span style="color: #f21000">*</span></label>
                        <select
                          required
                          style="
                            
                            height: 30px;
                            padding: 5px 5px 5px 5px;
                          "
                          class="form-select"
                          v-model="form.state_id"
                          aria-label="Default select example"
                        >
                          <!-- <option selected>States</option> -->
                          <option
                            v-for="(state, index) in states"
                            :key="index"
                            :value="state.id"
                          >
                            {{ state.name }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div
                      class="col-md-3 col-xl-3 col-lg-3 col-sm-6 col-12"
                      style="padding: 5px 5px 5px 5px"
                    >
                      <div class="form-group">
                        <label>Pin Code <span style="color: #f21000">*</span></label>
                        <input
                          required
                          type="number"
                          maxlength="6"
                          v-model="form.pin"
                          style=" height: 30px"
                          class="form-control"
                          placeholder="pin code"
                          @change="lenthheker($event, 'pinCode')"
                        />
                        <span style="color: #f21000" id="pinCode"></span>
                        <small style="color: red" v-if="form.errors"
                          ><span v-if="form.errors.error"
                            ><span v-if="form.errors.error.pin">{{
                              form.errors.error.pin[0]
                            }}</span></span
                          ></small
                        >
                      </div>
                    </div>
                  </div>
                  <div class="row mt-1">
                    <div class="col-12" v-if="msgImgError1">
                      <div class="alert alert-danger p-1" role="alert">
                        <small>{{ msgImgError1 }}</small>
                      </div>
                    </div>
                    <div class="col-2">
                      <div>
                        <label for="file-input1">
                        <img
                          style="width: 50%"
                          v-if="profilePreImg"
                          :src="profilePreImg"
                        />
                        </label>
                      </div>
                      <div class="d-flex">
                        <b>Profile Photo</b>
                        <!-- <label for="file-input1">
                          <img
                            style="width: 25px; height: 25px"
                            src="/assets/image/upload.png"
                          />
                        </label> -->
                        <input
                          @change="profileImageUpload"
                          style="position: absolute; top: -15000px"
                          id="file-input1"
                          type="file"
                        />
                        <small style="color: red" v-if="form.errors"
                          ><span v-if="form.errors.error"
                            ><span v-if="form.errors.error.profilePreImg">{{
                              form.errors.error.profilePreImg[0]
                            }}</span></span
                          ></small
                        >
                      </div>
                    </div>
                  </div>
                  <h4 class="mt-2" style="color: #f21300">
                    <b>Credentials </b>
                  </h4>
                  <div class="row" v-if="form.portal != 'false'">
                    <div
                      class="col-xl-3 col-md-3 col-sm-6 col-12"
                      style="padding: 5px 5px 5px 5px"
                    >
                      <div class="form-group">
                        <label>Secret Key <span style="color: #f21000">*</span></label>
                        <input
                          required
                          type="text"
                          v-model="form.secretKey"
                          class="form-control"
                          placeholder="Secret Key"
                          style="height: 30px"
                          @change="lenthheker($event, 'scretKeyLenth')"
                        />
                        <span style="color: #f21000" id="scretKeyLenth"></span>
                        <small style="color: red" v-if="form.errors"
                          ><span v-if="form.errors.error"
                            ><span v-if="form.errors.error.secretKey">{{
                              form.errors.error.secretKey[0]
                            }}</span></span
                          ></small
                        >
                      </div>
                    </div>
                    <div
                      class="col-xl-3 col-md-3 col-sm-6 col-12"
                      style="padding: 5px 5px 5px 5px"
                    >
                      <div class="form-group">
                        <label>Call Back Url <span style="color: #f21000">*</span></label>
                        <input
                          required
                          type="text"
                          v-model="form.callbackUrl"
                          class="form-control"
                          placeholder="Call Back URL"
                          style=" height: 30px"
                        />
                      </div>
                    </div>
                    <div
                      class="col-xl-3 col-md-3 col-sm-6 col-12"
                      style="padding: 5px 5px 5px 5px"
                    >
                      <div class="form-group">
                        <label>Success Url <span style="color: #f21000">*</span></label>
                        <input
                          required
                          type="text"
                          v-model="form.successUrl"
                          class="form-control"
                          placeholder="Success URL"
                          style=" height: 30px"
                        />
                      </div>
                    </div>
                    <div
                      class="col-xl-3 col-md-3 col-sm-6 col-12"
                      style="padding: 5px 5px 5px 5px"
                    >
                      <div class="form-group">
                        <label>Failed Url <span style="color: #f21000">*</span></label>
                        <input
                          required
                          type="text"
                          v-model="form.failedUrl"
                          class="form-control"
                          placeholder="Failed URL"
                          style=" height: 30px"
                        />
                      </div>
                    </div>
                    <div
                      class="col-xl-3 col-md-3 col-sm-6 col-12"
                      style="padding: 5px 5px 5px 5px"
                    >
                      <div class="form-group">
                        <label>End Point <span style="color: #f21000">*</span></label>
                        <input
                          type="text"
                          v-model="form.endpoint"
                          class="form-control"
                          placeholder="End Point"
                          style=" height: 30px"
                        />
                      </div>
                    </div>
                    <div
                      class="col-xl-3 col-md-3 col-sm-6 col-12"
                      style="padding: 5px 5px 5px 5px"
                    >
                      <div class="form-group">
                        <label>Method <span style="color: #f21000">*</span></label>
                        <input
                          required
                          type="text"
                          v-model="form.method"
                          class="form-control"
                          placeholder="Method"
                          style=" height: 30px"
                        />
                      </div>
                    </div>
                    <div
                      class="col-xl-3 col-md-3 col-sm-6 col-12"
                      style="padding: 5px 5px 5px 5px"
                    >
                      <div class="form-group">
                        <label>Parameter <span style="color: #f21000">*</span></label>
                        <input
                          type="text"
                          v-model="form.parameter"
                          class="form-control"
                          placeholder="Parameter"
                          style=" height: 30px"
                        />
                      </div>
                    </div>
                    <div
                      class="xol-xl-3 col-md-3 col-sm-6 col-12"
                      style="padding: 5px 5px 5px 5px"
                    >
                      <div class="form-group">
                        <label>Password</label>
                        <input
                          type="password"
                          v-model="form.password"
                          class="form-control"
                          placeholder="Password"
                          style=" height: 30px"
                        />
                        <small style="color: red" v-if="form.errors"
                          ><span v-if="form.errors.error"
                            ><span v-if="form.errors.error.password">{{
                              form.errors.error.password[0]
                            }}</span></span
                          ></small
                        >
                      </div>
                    </div>

                    <div
                      class="col-xl-3 col-md-3 col-sm-6 col-12"
                      style="padding: 5px 5px 5px 5px"
                    >
                      <div class="form-group">
                        <label>Login Status <span style="color: #f21000">*</span></label>
                        <select
                          class="form-select"
                          v-model="form.loginStatus"
                          aria-label="Default select example"
                          style="
                            
                            height: 30px;
                            padding: 5px 5px 5px 5px;
                          "
                        >
                          <option value="false">InActive</option>
                          <option value="true">Active</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="row" v-else>
                    <div
                      class="col-xl-3 col-md-3 col-sm-6 col-12"
                      style="padding: 5px 5px 5px 5px"
                    >
                      <div class="form-group">
                        <label>Secret Key <span style="color: #f21000">*</span></label>
                        <input
                          type="text"
                          v-model="form.secretKey"
                          class="form-control"
                          placeholder="Secret Key"
                          style="height: 30px"
                          @change="lenthheker($event, 'scretKeyLenth')"
                        />
                        <span style="color: #f21000" id="scretKeyLenth"></span>
                      </div>
                    </div>
                    <div
                      class="xol-xl-3 col-md-3 col-sm-6 col-12"
                      style="padding: 5px 5px 5px 5px"
                    >
                      <div class="form-group">
                        <label>Password</label>
                        <input
                          type="password"
                          v-model="form.password"
                          class="form-control"
                          placeholder="Password"
                          style=" height: 30px"
                        />
                        <small style="color: red" v-if="form.errors"
                          ><span v-if="form.errors.error"
                            ><span v-if="form.errors.error.password">{{
                              form.errors.error.password[0]
                            }}</span></span
                          ></small
                        >
                      </div>
                    </div>

                    <div
                      class="col-xl-3 col-md-3 col-sm-6 col-12"
                      style="padding: 5px 5px 5px 5px"
                    >
                      <div class="form-group">
                        <label>Login Status</label>
                        <select
                          style="
                            
                            height: 30px;
                            padding: 5px 5px 5px 5px;
                          "
                          class="form-select"
                          v-model="form.loginStatus"
                          aria-label="Default select example"
                        >
                          <option value="false">InActive</option>
                          <option value="true">Active</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col">
                      <div class="form-group mt-1">
                        <button
                          v-if="
                            msgImgError == '' &&
                            msgImgError1 == '' &&
                            doBDate == 'false' &&
                            doiDate == 'false' &&
                            adminCity == 'false' &&
                            adminAddress == 'false' &&
                            adminName == 'false'
                          "
                          type="submit"
                          class="btn btn-sm text-white"
                          style="background-color: #f21300; box-shadow: 1px 2px 5px gray"
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Content-->
    <div class="sidenav-overlay"></div>
    <div class="drag-target"></div>
  </div>
</template>
<script>
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import Spinner from "../../../components/super-admin/comman/Spinner.vue";
import Banner from "../../../components/retailer/comman/Banner.vue";
import Form from "vform";
import jQuery from "jquery";
let $ = jQuery;
export default {
  name: "Create Admin",
  components: {
    Banner,
    Spinner,
  },
  data() {
    return {
      loading: false,
      msgImgError: "",
      msgImgError1: "",
      states: [],
      // for priview images
      profilePreImg: this.$store.state.placeholderImg,
      coiPreImg: this.$store.state.placeholderImg,
      logoPreImg: this.$store.state.placeholderImg,
      gstinPreImg: this.$store.state.placeholderImg,
      tdsPreImg: this.$store.state.placeholderImg,
      cinPreImg: this.$store.state.placeholderImg,
      bankStatementPreImg: this.$store.state.placeholderImg,
      businessPanPreImg: this.$store.state.placeholderImg,
      // end for privie
      packages: [],
      routerIdRecived: "",
      form: new Form({
        code: "",
        phone: "",
        alternativePhone: "",
        dob: "",
        gender: "male",
        photo: "",
        logo: "",
        orgnization: "",
        coi: null,
        coiPhoto: "",
        gstin: null,
        gstinPhoto: "",
        cinPhoto: "",
        cin: null,
        tds: null,
        tdsPhoto: "",
        businessPan: null,
        businessPanPhoto: "",
        businessCity: "",
        business_state_id: 1,
        businessPin: null,
        address: "",
        city: "",
        pin: "",
        state_id: 1,
        bankStatement: "",
        email: "",
        password: "",
        kycNeed: "false",
        kycRequired: "false",
        secretKey: "",
        callbackUrl: "",
        successUrl: "",
        failedUrl: "",
        endpoint: "",
        method: "",
        parameter: "",
        loginStatus: "true",
        remark: "",
        portal: "",
      }),
      randomeScretKey: "",
      extensionCoi: "",
      extensionGstIn: "",
      extensionLogo: "",
      extensionTds: "",
      extensionBusinessPan: "",
      extensionBankStatement: "",
      extensionCin: "",
      extensionProfile: "",
      errors: {},

      submitStatus: "false",
      gstinVal: "false",
      doiDate: "false",
      doBDate: "false",
      adminName: "false",
      adminAddress: "false",
      adminCity: "false",
      bussinessCity: "false",
      businessAddress: "false",

      panVarifyingStatus: "false",
      msgPanError: "",
    };
  },
  methods: {
    nameKeydown(e) {
      if (/^\W$/.test(e.key)) {
        $("#businessPanVeryfiedAdmin").html(
          "<small>special characters are not allowed</small>"
        );
        e.preventDefault();
      } else {
        $("#businessPanVeryfiedAdmin").html("<small></small>");
      }
    },
    nameKeydownGst(e) {
      if (/^\W$/.test(e.key)) {
        $("#gstinVal").html(
          "<small>special characters are not allowed</small>"
        );
        e.preventDefault();
      } else {
        $("#gstinVal").html("<small></small>");
      }
    },
    dateCheker(event, type) {
      var currentDateWithFormat = new Date().toJSON().slice(0, 10).replace(/-/g, "-");
      var data = event.target.value;
      if (type == "doiDate") {
        if (currentDateWithFormat < data) {
          this.doiDate = "true";
          $("#doiDate").html("<small>It should be less than current date</small>");
        }
        if (currentDateWithFormat >= data) {
          this.doiDate = "false";
          $("#doiDate").html("<small></small>");
        }
      }

      if (type == "doBDate") {
        if (currentDateWithFormat < data) {
          this.doBDate = "true";
          $("#doBDate").html("<small>It should be less than current date</small>");
        }
        if (currentDateWithFormat >= data) {
          this.doBDate = "false";
          $("#doBDate").html("<small></small>");
        }
      }
      // }
    },
    // lenthhekerBusinesPan(){
    // },
    lenthheker(event, type) {
      var data = event.target.value;
      if (type == "phone") {
        if (data.length != 10) {
          this.submitStatus = "true";
          $("#demophoneval").html("<small>Phone Number 10 Digit Required</small>");
        }
        if (data.length == 10) {
          this.submitStatus = "false";
          $("#demophoneval").html("<small></small>");
        }
      }
      // if (type == "alt") {
      //   if (data.length != 10) {
      //     this.submitStatus = "true";
      //     $("#demoAltphoneval").html("<small>Alt Phone Number 10 Digit Required</small>");
      //   }
      //   if (data.length == 10) {
      //     this.submitStatus = "false";
      //     $("#demoAltphoneval").html("<small></small>");
      //   }
      // }

      if (type == "businessPinCode") {
        if (data.length != 6) {
          this.submitStatus = "true";
          $("#businessPinCode").html("<small>Pin Code 6 Digit Required</small>");
        }
        if (data.length == 6) {
          this.submitStatus = "false";
          $("#businessPinCode").html("<small></small>");
        }
      }

      if (type == "pinCode") {
        if (data.length != 6) {
          this.submitStatus = "true";
          $("#pinCode").html("<small>Pin Code 6 Digit Required</small>");
        }
        if (data.length == 6) {
          this.submitStatus = "false";
          $("#pinCode").html("<small></small>");
        }
      }

      if (type == "gstinVal") {
        if (data.length != 15) {
          this.gstinVal = "true";
          $("#gstinVal").html("<small>GSTIN 15 Digit Required</small>");
        }
        if (data.length == 15) {
          this.gstinVal = "false";
          $("#gstinVal").html("<small></small>");
        }
      }

      if (type == "adminName") {
        if (data.length < 3) {
          this.adminName = "true";
          $("#adminName").html("<small>Admin Name required minimum 3 letters</small>");
        }
        if (data.length >= 3) {
          this.adminName = "false";
          $("#adminName").html("<small></small>");
        }
      }

      if (type == "adminAddress") {
        if (data.length < 3) {
          this.adminAddress = "true";
          $("#adminAddress").html("<small>Address required minimum 3 letters</small>");
        }
        if (data.length >= 3) {
          this.adminAddress = "false";
          $("#adminAddress").html("<small></small>");
        }
      }
      if (type == "adminCity") {
        if (data.length < 3) {
          this.adminCity = "true";
          $("#adminCity").html("<small>City required minimum 3 letters</small>");
        }
        if (data.length >= 3) {
          this.adminCity = "false";
          $("#adminCity").html("<small></small>");
        }
      }

      if (type == "businessAddress") {
        if (data.length < 3) {
          this.businessAddress = "true";
          $("#businessAddress").html("<small>Address required minimum 3 letters</small>");
        }
        if (data.length >= 3) {
          this.businessAddress = "false";
          $("#businessAddress").html("<small></small>");
        }
      }

      if (type == "bussinessCity") {
        if (data.length < 3) {
          this.bussinessCity = "true";
          $("#bussinessCity").html("<small>City required minimum 3 letters</small>");
        }
        if (data.length >= 3) {
          this.bussinessCity = "false";
          $("#bussinessCity").html("<small></small>");
        }
      }

      if (type == "scretKeyLenth") {
        if (data.length < 10) {
          this.submitStatus = "true";
          $("#scretKeyLenth").html(
            "<small>Secret Key required minimum 10 letters</small>"
          );
        }
        if (data.length >= 10) {
          this.submitStatus = "false";
          $("#scretKeyLenth").html("<small></small>");
        }
      }
    },
    businessPanVeryfied() {
      var data = this.form.businessPan;
      if (data.length == 10) {
        this.submitStatus = "false";
        $("#businessPanVeryfiedAdmin").html("<small></small>");
        this.msgPanError = "";
        this.loading = true;
        this.$axios
          .post(
            "superadmin/verifypan",
            { panNo: this.form.businessPan },
            { headers: { Authorization: "Bearer " + localStorage.accessToken } }
          )
          .then((res) => {
            if (res.data.data.data) {
              $("#businessPanVeryfiedAdmin").html(
                "<small>" + res.data.data.data.status + " Pan </small>"
              );
            }
            if (res.data.data.data.full_name) {
              $("#businessPanVeryfiedAdmin").html("<small></small>");
              this.form.orgnization = res.data.data.data.full_name;
              this.panVarifyingStatus = "true";
              toast.success("Business Pan Verified Succesfully ", {
                autoClose: 1000,
              });
            } else {
              this.msgPanError = res.data.data.message;
            }
          })
          .finally(() => (this.loading = false))
          .catch((error) => {

this.$swal.fire({
      position: "top-center",
      icon: "error",
      title: "<small>" + error.response.data.error + "</small>",
      showConfirmButton: true,
      confirmButtonColor: "#00364f",
    });



  });

      } else {
        this.submitStatus = "true";
        $("#businessPanVeryfiedAdmin").html(
          "<small> Business Pan required minimum 10 Digits</small>"
        );
      }

      // this.panNo = ''
    },

    loadStates() {
      this.loading = true;
      this.$axios
        .get("superadmin/state?all=all", {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          this.states = res.data.data;
        })
        .finally(() => (this.loading = false));
    },
    profileImageUpload(e) {
      this.msgImgError1 = "";
      if (e.target.files["0"].size > 2 * (1024 * 1024)) {
        e.preventDefault();
        this.msgImgError1 = "Profile Image Size Too Big 2MB";
        return false;
      } else {
        var fileName;
        fileName = document.querySelector("#file-input1").value;
        this.extensionProfile = fileName.split(".").pop();
        if (
          this.extensionProfile == "png" ||
          this.extensionProfile == "jpg" ||
          this.extensionProfile == "jpeg" ||

          this.extensionProfile == "PNG" ||
          this.extensionProfile == "JPG" ||
          this.extensionProfile == "JPEG" 
        ) {
          this.profilePreImg = URL.createObjectURL(e.target.files["0"]);
          this.form.photo = e.target.files["0"];
        } else {
          this.msgImgError1 = "File Type Must JPG , PNG, JPEG";
        }
      }
    },
    logoUpload(e) {
      this.msgImgError = "";
      if (e.target.files["0"].size > 2 * (1024 * 1024)) {
        e.preventDefault();
        this.msgImgError = "Logo Image Size Too Big 2MB";
        return false;
      } else {
        var fileName;
        fileName = document.querySelector("#file-input2").value;
        this.extensionLogo = fileName.split(".").pop();
        if (
          this.extensionLogo == "png" ||
          this.extensionLogo == "jpg" ||
          this.extensionLogo == "jpeg" ||
          this.extensionLogo == "PNG" ||
          this.extensionLogo == "JPG" ||
          this.extensionLogo == "JPEG"
        ) {
          this.logoPreImg = URL.createObjectURL(e.target.files["0"]);
          this.form.logo = e.target.files["0"];
        } else {
          this.msgImgError = "File Type Must JPG , PNG, JPEG";
        }
      }
    },

    createAdmin() {
      if (
        this.gstinVal == "false" &&
        this.doiDate == "false" &&
        this.doBDate == "false" &&
        this.adminName == "false" &&
        this.adminAddress == "false" &&
        this.adminCity == "false" &&
        this.submitStatus == "false" &&
        this.bussinessCity == "false" &&
        this.businessAddress == "false" &&
        this.msgImgError == "" &&
        this.msgImgError1 == ""
      ) {
        const formData = new FormData();
        const keys = Object.keys(this.form);
        for (let n = 0; n < keys.length; n += 1) {
          if (this.form[keys[n]] !== null) {
            formData.append(keys[n], this.form[keys[n]]);
          }
        }
        this.loading = true;
        this.$axios
          .post("superadmin/admin", formData, {
            headers: { Authorization: "Bearer " + localStorage.accessToken },
          })
          .then((res) => {
            res;
            toast.success("Admin Created  Succesfully", {
              autoClose: 1000,
            });
            this.$router.push({
              path: `/super-admin/admins`,
              query: { portal: this.$route.query.portal },
            });
          })
          .catch((error) => {
            this.form.errors = error.response.data;
          })

          .finally(() => (this.loading = false));
      }
    },
    updateAdmin() {
      if (
        this.gstinVal == "false" &&
        this.doiDate == "false" &&
        this.doBDate == "false" &&
        this.adminName == "false" &&
        this.adminAddress == "false" &&
        this.adminCity == "false" &&
        this.submitStatus == "false" &&
        this.bussinessCity == "false" &&
        this.businessAddress == "false" &&
        this.msgImgError == "" &&
        this.msgImgError1 == ""
      ) {
        const formData = new FormData();
        const keys = Object.keys(this.form);
        for (let n = 0; n < keys.length; n += 1) {
          if (this.form[keys[n]] !== null) {
            formData.append(keys[n], this.form[keys[n]]);
          }
        }
        this.loading = true;
        this.$axios
          .post(`superadmin/admin/${this.$route.params.id}?_method=PUT`, formData, {
            headers: { Authorization: "Bearer " + localStorage.accessToken },
          })
          .then((res) => {
            res;
            toast.success("Admin Updated  Succesfully", {
              autoClose: 1000,
            });
            this.$router.push({
              path: `/super-admin/admins`,
              query: { portal: this.$route.query.portal },
            });
          })
          .finally(() => (this.loading = false))
          .catch((error) => {
            this.form.errors = error.response.data;
          });
      }
    },
    editAdmin() {
      if (this.$route.params.id) {
        this.routerIdRecived = "true";
        this.loading = true;
        this.$axios
          .get(`superadmin/admin/${this.$route.params.id}`, {
            headers: { Authorization: "Bearer " + localStorage.accessToken },
          })
          .then((res) => {
            this.form.code = res.data.data.code;
            this.form.name = res.data.data.name;
            this.form.phone = res.data.data.phone;
            this.form.alternativePhone = res.data.data.alternativePhone;
            this.form.dob = res.data.data.dob;
            this.form.doi = res.data.data.doi;
            this.form.gender = res.data.data.gender;
            this.form.photo = res.data.data.photo;
            this.form.logo = res.data.data.logo;
            this.form.orgnization = res.data.data.orgnization;
            this.form.coi = res.data.data.coi;
            this.form.gstin = res.data.data.gstin;
            this.form.cin = res.data.data.cin;
            this.form.tds = res.data.data.tds;
            this.form.businessPan = res.data.data.businessPan;
            this.form.businessCity = res.data.data.businessCity;
            this.form.businessAddress = res.data.data.businessAddress;
            this.form.business_state_id = res.data.data.business_state_id;
            this.form.businessPin = res.data.data.businessPin;
            this.form.address = res.data.data.address;
            this.form.city = res.data.data.city;
            this.form.pin = res.data.data.pin;
            this.form.state_id = res.data.data.state_id;
            this.form.email = res.data.data.email;
            this.form.password = "";
            this.form.kycNeed = res.data.data.kycNeed;
            this.form.kycRequired = res.data.data.kycRequired;
            this.form.secretKey = res.data.data.secretKey;
            this.form.callbackUrl = res.data.data.callbackUrl;
            this.form.successUrl = res.data.data.successUrl;
            this.form.failedUrl = res.data.data.failedUrl;
            this.form.endpoint = res.data.data.endpoint;
            this.form.method = res.data.data.method;
            this.form.parameter = res.data.data.parameter;
            this.form.loginStatus = res.data.data.loginStatus;
            this.form.loginStatus = res.data.data.loginStatus;
            this.form.remark = res.data.data.remark;
            this.form.portal = res.data.data.portal;
            this.panVarifyingStatus = "true";
            if (res.data.data.logo != null) {
              if (
                this.$store.state.imgUrl +
                "/admin/" +
                res.data.data.id +
                "/thumbs/" +
                res.data.data.logo
              ) {
                this.logoPreImg =
                  this.$store.state.imgUrl +
                  "/admin/" +
                  res.data.data.id +
                  "/thumbs/" +
                  res.data.data.logo;
              }
              if (
                this.$store.state.imgUrl +
                "/admin/" +
                res.data.data.id +
                "/" +
                res.data.data.logo
              ) {
                this.logoPreImg =
                  this.$store.state.imgUrl +
                  "/admin/" +
                  res.data.data.id +
                  "/" +
                  res.data.data.logo;
              }

              this.extensionLogo = this.logoPreImg.split(".").pop();

              if (this.extensionLogo == "pdf") {
                this.logoPreImg = "/assets/image/pdf.png";
              }
            } else {
              this.logoPreImg = this.$store.state.placeholderImg;
            }

            if (res.data.data.photo != null) {
              this.profilePreImg =
                this.$store.state.imgUrl +
                "/admin/" +
                res.data.data.id +
                "/thumbs/" +
                res.data.data.photo;
            } else {
              this.profilePreImg = this.$store.state.placeholderImg;
            }

            this.form.logo = "";
            this.form.photo = "";
          })
          .finally(() => (this.loading = false));
      } else {
        this.form.secretKey = this.randomeScretKey;
        this.routerIdRecived = "false";
      }
    },
    generate() {
      var value;
      value = Math.random().toString(36).slice(2);
      this.randomeScretKey = value;
      this.form.secretKey = this.value;
    },
    loadPageTitle(){
      console.log('--------------------------');
        
        document.title =this.$store.state.superAdminPageTitles.adminCreate
    console.log(this.$store.state.superAdminPageTitles.adminCreate)
    console.log('--------------------------');
    }
  },
  created() {
    if (
      this.$route.meta.redirectIfLoggedIn == "true" &&
      localStorage.getItem("accessToken") == null
    ) {
      this.$router.push({ name: "Login" });
    } else {
      this.editAdmin();
      this.loadStates();
      this.form.secretKey = Math.random().toString(36).slice(2);
      this.form.portal = this.$route.query.portal;

   this.loadPageTitle()

    }
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");

.custom-card {
  border-radius: 10px;
  box-shadow: 0px 0px 5px 0px rgba(206, 205, 205, 0.555);
  background-color: white;
}
.h4-heading {
  color: red;
  font-size: 1.2vw;
}

.font-heading {
  font-family: "Poppins", sans-serif;
  font-size: 3rem;
  color: rgb(216, 209, 209);
}

.text {
  font-family: "Poppins", sans-serif;
  color: #00364f;
}

.font {
  font-family: "Poppins", sans-serif;
}

.btns {
  background-color: #00364f;
  font-size: 12px;
  padding: 8px 8px 8px 8px;
}

.radius {
  border-radius: 23px;
}

.form-check-input:checked {
  background-color: #f21300;
  border-color: #f21300;
}

.tr-head {
  font-size: 11pt;
  margin-left: 25px;
  border-radius: 26px;
  background-color: #cdcdcd;
}

.table-scroll {
  overflow-y: auto;
}
</style>
